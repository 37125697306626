

/* Title */
.header-background-image-container {
  -webkit-mask-size: auto auto;
  mask-size: auto auto;
  position: relative;
  background-color: #0a3863;
}

.title-background-image {
  vertical-align: top;
  width: 100%;
  height: auto;
  opacity: .75;
}

.background-text {
  position: absolute;
  color: white;
  font-weight: bold;
  text-align: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* Other styles as needed */
  font-family: 'Poppins', sans-serif;
  text-shadow: 3px 3px 5px rgba(0,0,0,1);
  font-size: 4vw;
  width: 80%;
}

/* NAVBAR */

.footer-logo-container,
.navbar-logo-container{
  max-width: 20%;
}

.company-logo {
  max-width: 140px;
  width: 80%;
  height: auto;
}


.footer-contant-area,
.navbar-content-area {
  background-color: #ffffff;
  height: 100px;
  width: 90%;
  padding: 1% 5% 1% 5%;
}

.footer-containers,
.navbar-containers {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 80%; /* Adjust the height for small screens */
  margin: auto;
}

.footer-page-titles-container,
.navbar-page-titles-container {
  display: flex;
  flex-direction: row;
  max-width: 80%;
  overflow: hidden;
}

.navbar-link {
  flex: 1;
  text-align: center;
  display: flex;
  font-display: flex;
  margin: 0px calc(5px + 1vmin) 0px calc(5px + 1vmin);
  font-size: calc(3px + 2vmin);
  font-weight: 500;
  font-family: 'Poppins', sans-serif;
  text-decoration: none;
  color: #0a3863;
  /* width: 25%; */
  white-space: nowrap;
}

.contact-title,
.contact-link {
  flex: 1;
  background-color: red;
  color: white;
  text-align: center;
  display: flex;
  font-display: flex;
  padding: 0px calc(5px + 1vmin) 0px calc(5px + 1vmin);
  font-weight: 500;
  font-family: 'Poppins', sans-serif;
  text-decoration: none;
  font-size: calc(3px + 2vmin);
  white-space: nowrap;
}


.section-title {
  font-size: calc(6px + 3vmin);
  font-weight: 500;
  font-family: 'Poppins', sans-serif;
  text-align: center;
  background-color: #e81c27;
  max-width: 40%;
  margin: 5% auto;
  color: #ffffff;
}

/* About */

.about-container {
  display: flex;
  position: relative;
  width: 100%;
  height: 350px;
}

.text-section {
  flex: 1;
  padding: 2% 2% 2% 2%;
  display: flex;
  justify-content: space-evenly;
  align-items: center; /* Align items vertically */
  flex-direction: column;
}

.about-title {
  background-color: #0a3863;
  color: white;
  text-align: center;
  padding: 0% 5% 0px 5%;
  margin-bottom: 5%;
  font-size: calc(6px + 3vmin);
  font-weight: 500;
  font-family: 'Poppins', sans-serif;
}

.about-text {
  color: #0a3863;
  text-align: center;
  max-width: 80%;
  margin: 0px calc(5px + 1vmin) 0px calc(5px + 1vmin);
  font-size: calc(4px + 2vmin);
  font-weight: bold;
  font-family: 'Poppins', sans-serif;
}

.image-section {
  flex: 2;
  position: relative;
  display: flex;
  overflow: hidden;
  width: 60%;
  max-height: 100%; /* Allow images to scale */
}

.first-image {
  flex: 1;
  position: relative;
  height: 100%;
}
.second-image {
  flex: 2;
  position: relative;
}

.first-image img {
  width: auto;
  height: 100%;
  max-height: 100%;
  object-fit: cover;
}

.second-image img {
  width: auto;
  height: 100%;
  max-height: 100%;
  object-fit: cover;
}

 @media screen and (max-width: 650px) {
  .about-container {
      flex-direction: column;
      height: 500px;
      display: flex;
      align-items: center;
      justify-content: center;
  }
  .text-section {
    max-width: 50%;
  }
  .image-section {
    width: 100%;
  }

  .first-image {
    flex: 1;
    position: relative;
    width: 100%;
  }
  .second-image {
    flex: 1;
    position: relative;
    min-width: 45%;
  }
  .second-image img {
    width: 100%;
    height: auto;
    min-height: 100%;
    object-fit: cover;
  }
}

/* Services */

.services{
  background-color: #7b8ba1;
}

.services-title {
  padding: 3% 3% 3% 3%;
  text-align: center;
  font-size: calc(6px + 3vmin);
  color: white;
  font-weight: 500;
  font-family: 'Poppins', sans-serif;
  padding-top: 5%;
}

.services-container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  padding: 0% 3% 3% 3%;
}

.service-item{
  align-items: center;
  width: 30%;
}

.service-item img{
  width: 100%;
  height: auto;
}

.service-title{
  color: white;
  text-align: center;
  padding: 0% 5% 0px 5%;
  margin-bottom: 5%;
  font-size: calc(5px + 2vmin);
  font-weight: 500;
  font-family: 'Poppins', sans-serif;

}

/* Portfolio */

.portfolio{
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #dde3ef;
}

.portfolio-title {
  background-color: #0a3863;
  color: white;
  text-align: center;
  padding: 0% 2% 0% 2%;
  font-size: calc(6px + 3vmin);
  font-weight: 500;
  font-family: 'Poppins', sans-serif;
  margin: 5% 5% 5% 5%;
}

.portfolio-container {
  display: flex;
  width: 67%;
  display: flex;
  flex-direction: column;
}

.portfolio-item {
  background-color: white;
  width: 100%;
  height: 350px;
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  /* box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.2); */
}

.portfolio-image-switch,
.portfolio-image{
  flex: 0 0 50%;
  max-width: 50%;
  height: 100%;
  width: auto;
  object-fit: cover;
}

.portfolio-image-switch{
  order: 2;
}

.portfolio-text-section-switch,
.portfolio-text-section{
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  margin: 0% 3% 0% 3%;
}

.portfolio-text-section-switch{
  order: 1;
}


.project-title {
  background-color: #0a3863;
  color: white;
  text-align: center;
  padding: 0% 2% 0% 2%;
  font-size: calc(8px + 2vmin);
  font-weight: 500;
  font-family: 'Poppins', sans-serif;
  margin: 2% 2% 5% 2%;
}

.project-quote{
  text-align: center;
  color: #0a3863;
  font-family: 'Poppins', sans-serif;
  font-size: calc(6px + 1.5vmin);
  margin: 1% 1% 1% 1%;
}

.quote-name{
  text-align: center;
  font-family: 'Poppins', sans-serif;
  color: #0a3863;
  font-size: calc(6px + 2vmin);
  margin: 1% 1% 1% 1%;
}

.big-quote-section{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: #0a3863;
  color: white;
}

.big-quote-title{
  text-align: center;
  font-size: calc(3px + 2vmin);
  font-weight: 500;
  font-family: 'Poppins', sans-serif;
  margin: 2% 2% 2% 2%;
}

.big-quote{
  text-align: center;
  font-family: 'Poppins', sans-serif;
  font-size: calc(2px + 2vmin);
  margin: 2% 2% 2% 2%;
}
.big-quote-name{
  text-align: center;
  font-family: 'Poppins', sans-serif;
  margin: 2% 2% 2% 2%;
}
@media screen and (max-width: 650px) {
  .portfolio-item{
    flex-direction: column;
    height: auto;
  }
  .portfolio-image-switch,
  .portfolio-image{
    /* flex: 1; */
    max-width: 100%;
    width: 100%;
    height: auto;
    object-fit: cover;
    order: 1;
  }

  .portfolio-text-section-switch,
  .portfolio-text-section{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    margin: 0% 3% 0% 3%;
    order: 2;
  }
}

/* Footer */
.footer-page-titles-container{
  flex-direction: column;
  text-align: center;
}

.contact-section{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contact-content{
  font-family: 'Poppins', sans-serif;
  color: #e81c27;
  font-size: calc(2px + 1.5vmin);
}